<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1000px'"
    :min-size="nested ? 800 : 1000"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Workload ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> Workload: '{{ selected.name }}'
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="workloadUrl"
          title="Workload"
        ></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a Workload
      </h3>
      <div>
        <v-btn
          @click="switchToEditMode()"
          v-if="readonly && !isFetching && isMainRoute"
          color="info"
          small
          class="ml-2"
          style="height: 24px; font-size: 12px"
        >
          <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
        </v-btn>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-form v-model="valid" ref="updateForm">
      <v-container>
        <v-row>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.required, allRules.noWhiteSpaces]"
              label="Name"
              id="name"
              placeholder="Name"
              v-model="selected.name"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0"></v-col>
          <v-col cols="12" md="12" sm="12" class="py-0"><v-divider></v-divider></v-col>
          <v-col cols="12" md="12" sm="12" class="py-0">
            <h3 class="mt-3 mb-2"><i class="fad fa-calendar-week mr-2"></i>Workload Hours</h3>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Monday"
              placeholder="Monday"
              v-model.number="selected.monday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Tuesday"
              placeholder="Tuesday"
              v-model.number="selected.tuesday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Wednesday"
              placeholder="Wednesday"
              v-model.number="selected.wednesday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Thursday"
              placeholder="Thursday"
              v-model.number="selected.thursday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Friday"
              placeholder="Friday"
              v-model.number="selected.friday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Saturday"
              placeholder="Saturday"
              v-model.number="selected.saturday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="3" sm="12" class="py-0">
            <v-text-field-alt
              :rules="[allRules.requiredNumber, allRules.number]"
              label="Sunday"
              placeholder="Sunday"
              v-model.number="selected.sunday"
              suffix="h"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="py-0">
            <v-textarea-alt
              :rules="[allRules.required]"
              label="Note"
              id="Note"
              placeholder="Note"
              v-model="selected.notes"
              :readonly="readonly"
              rows="3"
            >
            </v-textarea-alt>
          </v-col>
          <v-col cols="12" md="12" sm="12" class="py-0"><v-divider></v-divider></v-col>
          <v-col cols="12" md="12" sm="12" class="py-0">
            <h3 class="mt-3 mb-2"><i class="fad fa-users mr-2"></i>Workload Users</h3>
          </v-col>
          <v-col cols="12">
            <div style="background: #eceff1; border-radius: 0.5rem" class="pa-4 inset-shadow">
              <v-btn @click="openUserSlide()" :disabled="readonly" color="info" class="mb-2">
                <i class="fas fa-plus mr-2" style="font-size: 10px"></i>Add User
              </v-btn>
              <v-data-table
                dense
                :headers="headers"
                :items="selected.users"
                class="elevation-2"
                hide-default-footer
                disable-pagination
                item-key="id"
                :loading="isFetching"
              >
                <template v-slot:no-data>
                  <img width="300" src="/img/art/fogg-no-connection-2.png" />
                  <p class="font-weight-bold">No Data Available!</p>
                </template>
                <template v-slot:[`item.firstName`]="{ item }">
                  <v-row style="margin: 5px 0px">
                    <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
                  </v-row>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <a :href="'mailto:' + item.email">{{ item.email }}</a>
                </template>

                <template v-slot:[`item.roles`]="{ item }">
                  <v-row style="margin: 5px 0px">
                    <v-chip
                      style="font-size: 11px; font-weight: 600"
                      v-for="role in item.roles"
                      :key="role.name"
                      label
                      x-small
                      color="deep-purple"
                      text-color="white"
                      :ripple="false"
                      link
                    >
                      {{ role.name }}
                    </v-chip>
                  </v-row>
                </template>

                <template v-slot:[`item.joinDate`]="{ item }">
                  <dater :date="item.joinDate" date-only></dater>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn :disabled="readonly" color="error" icon @click="del(item.id)"
                    ><v-icon>fal fa-trash-alt</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <add-users-to
      @confirm="slideoutsConfirmed"
      :disabled-users="selected.users"
      :nested="true"
      ref="addUserTo"
    ></add-users-to>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
        <v-btn
          v-if="!readonly"
          color="info"
          :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="updateConfirmed()"
          :loading="slideouts.update.isLoading"
        >
          <i class="fal mr-2" :class="selected.id ? 'fa-check' : 'fa-plus'"></i>
          {{ selected.id ? "Save Changes" : "Create" }}
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/workload-service.js";
import userHeader from "../../../features/Admin/config/tables/user.header";
import AddUsersTo from "./AddUsersTo.vue";

export default {
  name: "edit-workload",
  data() {
    return {
      workloadId: null,
      isFetching: false,
      valid: false,
      selected: {
        name: "",
        hours: "",
        users: [],
        notes: "",
      },
      readonly: false,
      selectedCemented: {},
      headers: userHeader,

      slideouts: {
        update: {
          active: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    workloadUrl() {
      if (this.selected == null) return "";
      return `workload/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-workload") return true;
      else return false;
    },
  },
  methods: {
    openUserSlide() {
      this.$refs.addUserTo.open();
    },
    slideoutsConfirmed(newUsers) {
      newUsers.forEach((elm) => {
        const index = this.selected.users.findIndex((user) => user.id == elm.id);
        if (index == -1) {
          this.selected.users.push(elm);
        } else {
          this.selected.users.splice(index, 1, elm);
        }
      });
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this user?`,
          title: `Delete User?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                const index = this.selected.users.findIndex((elm) => elm.id == id);
                if (index !== -1) {
                  this.selected.users.splice(index, 1);
                  this.$dialog.notify.info(
                    "The deletion will take effect after the changes has been saved",
                    {
                      position: "top-right",
                      timeout: 3000,
                    }
                  );
                }
              },
            },
          },
        })
        .then((res) => {});
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = { name: "", hours: "", users: [], notes: "" };
      this.cementWorkload();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewWorkload) {
      this.$emit("save", this.selected, isNewWorkload);
    },
    updateConfirmed() {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      toSend.users = toSend.users.map((elm) => elm.id);

      ApiService.update(toSend)
        .then((resp) => {
          this.$log(">>> updated", resp.data);
          var message = "Workload updated successfully!";
          this.selected = resp.data;
          this.cementWorkload();
          var isNewWorkload = false;
          if (!toSend.id) {
            isNewWorkload = true;
            message = "Workload added successfully!";
          }
          this.announceChange(isNewWorkload);
          this.closeUpdateSlideout();

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      if (id == null) {
        this.readonly = false;
        this.isFetching = false;
        this.workloadId = null;
        this.cementWorkload();
        setTimeout(() => {
          this.$refs.updateForm.resetValidation();
        });
      } else {
        this.readonly = !editMode;
        this.workloadId = id;
        this.fetchWorkload();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
      }, 250);
    },

    fetchWorkload() {
      this.isFetching = true;
      ApiService.getWorkloadById(this.workloadId)
        .then((resp) => {
          this.$log("getWorkloadById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementWorkload();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getWorkloadById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementWorkload() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementWorkload()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
  },

  watch: {
    selected: {
      handler(val) {
        this.checkForChanges();
      },
      deep: true,
    },
  },
  components: {
    AddUsersTo,
  },
};
</script>
